import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Typography, Divider, Image } from 'antd';
import axios from 'axios';
import { ShoppingCartOutlined } from '@ant-design/icons';
import endpoints from '../apis/apiConfig';
import { CartContext } from '../context/CartContext';
import { formatCurrency } from '../utils/formatNumber';

const { Title, Text } = Typography;

const ProductDetail = () => {
    const { productId } = useParams();
    const { addToCart } = useContext(CartContext);
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`${endpoints.products}/${productId}`);
                setProduct(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching product data:", error);
            }
        };

        fetchProduct();
    }, [productId]);

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <Card>
            <Row gutter={16}>
                <Col xs={24} sm={12}>
                    <Image
                        preview={true}
                        src={product.image_url}
                        alt={product.name}
                        width={250}

                    />
                </Col>
                <Col xs={24} sm={12}>
                    <Title level={2}>{product.name}</Title>
                    {/* <Text type="secondary">{product.description}</Text> */}
                    <Divider />
                    <Title level={4} style={{color: 'red'}}>ราคา: {formatCurrency(product.price)} บาท</Title>
                    <Text>{product.description}</Text>
                    <Divider />
                    <Button type="primary"
                        icon={<ShoppingCartOutlined />}
                        onClick={() => addToCart(product)}
                        block
                    >
                        หยิบใส่ตะกร้า
                    </Button>
                </Col>
            </Row>
        </Card>
    );
};

export default ProductDetail;
