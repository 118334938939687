import { jwtDecode} from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

export const isAuthenticated = () => {
  const token = localStorage.getItem('token');
  
  if (!token) {
    return false;
  }

  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Get current time in seconds

    if (decodedToken.exp < currentTime) {
      // Token is expired
      localStorage.removeItem('token');
      return false;
    }

    // Token is valid
    return true;
  } catch (error) {
    // If there's an error decoding the token, assume it's invalid
    localStorage.removeItem('token');
    return false;
  }
};
