// src/components/FileViewer.js

import React, { useRef, useEffect, useState } from 'react';
import WebViewer from '@pdftron/webviewer';
import { Typography, Spin } from 'antd';
const { Title } = Typography;

const FileViewer = () => {
  const viewer = useRef(null);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    WebViewer.WebComponent(
      {
        path: 'lib',
        licenseKey: 'demo:1724606331249:7e413cc603000000006d90e251e05f4a030585564f55051aa055245e25',
        initialDoc: 'https://www.jayasaro.panyaprateep.org/uploads/book/1/6/files/00000006.pdf',
      },
      viewer.current,
    ).then((instance) => {
      const { documentViewer, annotationManager, Annotations } = instance.Core;

      documentViewer.addEventListener('documentLoaded', () => {
        // PDF is loaded, set loading to false
        setLoading(false);

        const rectangleAnnot = new Annotations.RectangleAnnotation({
          PageNumber: 1,
          X: 100,
          Y: 150,
          Width: 200,
          Height: 50,
          Author: annotationManager.getCurrentUser(),
        });

        annotationManager.addAnnotation(rectangleAnnot);
        annotationManager.redrawAnnotation(rectangleAnnot);
      });
    });
  }, []);

  return (
      <div className="webviewer" ref={viewer} style={{ visibility: loading ? 'hidden' : 'visible', height: '100vh' }}></div>
  );
};

export default FileViewer;
