import React, { useState } from 'react';
import { Form, Input, Button, notification, Divider } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import endpoints from '../apis/apiConfig';

const Signup = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSignup = async (values) => {
    setLoading(true);
    try {
      await axios.post(endpoints.register, values);
      notification.success({
        message: 'Signup Successful',
        description: 'You can now log in with your credentials.',
      });
      navigate('/');
    } catch (error) {
      notification.error({
        message: 'Signup Failed',
        description: 'Please try again.',
      });
    } finally {
      setLoading(false);
    }
  };

  const validatePassword = (_, value) => {
    if (value && value.length < 6) {
      return Promise.reject(new Error('Password must be at least 6 characters long!'));
    }
    return Promise.resolve();
  };

  const validatePhone = (_, value) => {
    if (!value) {
      return Promise.reject(new Error('Phone number is required!'));
    }
    return Promise.resolve();
  };
  
  const validateName = (_, value) => {
    if (!value) {
      return Promise.reject(new Error('Phone number is required!'));
    }
    return Promise.resolve();
  };

  return (
    <div className="signup-container">
      <h2>ลงทะเบียน</h2>
      <Form
        name="signup"
        layout="vertical"
        onFinish={handleSignup}
        style={{ maxWidth: 400, margin: 'auto' }}
      >
        <Form.Item
          name="name"
          label="ชื่อ"
          rules={[{ required: true, type: 'name', message: 'กรุณาป้อนชื่อ-นามสกุล!', validator: validateName }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="address"
          label="ที่อยู่ร้าน"
          rules={[{ required: false, message: 'ป้อนที่อยู่ร้านค้า' }]}
        >
          <Input.TextArea placeholder="ป้อนที่อยู่ร้านค้าของคุณ" rows={4} />
        </Form.Item>
        <Form.Item
          name="phone"
          label="เบอร์ที่สามารถติดต่อได้"
          rules={[{ required: true, type: 'phone', message: 'กรุณาระบุเบอร์โทรที่ถูกต้อง!', validator: validatePhone  }]}
        >
          <Input />
        </Form.Item>
        <Divider
          style={{
            borderColor: '#7cb305',
          }}
        >
          สำหรับเข้าใช้ระบบสมาชิก
        </Divider>
        <Form.Item
          name="email"
          label="อีเมล์"
          rules={[{ required: true, type: 'email', message: 'กรุณาป้อนอีเมล์ที่ถูกต้อง!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="password"
          label="รหัสผ่าน"
          rules={[{ required: true, validator: validatePassword }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          label="ยืนยันรหัสผ่านอีกครั้ง"
          dependencies={['password']}
          hasFeedback
          rules={[
            { required: true, message: 'กรุณายืนยันรหัสผ่าน!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('รหัสผ่านไม่ตรงกัน!'));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            ลงทะเบียน
          </Button>
        </Form.Item>
      </Form>
      <Button type="link" onClick={() => navigate('/login')}>เข้าสู่ระบบ</Button>
    </div>
  );
};

export default Signup;
