import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Row, Col, message } from 'antd';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { CartProvider } from './context/CartContext';
import "./App.css";

//authorized
import ProtectedRoute from './components/ProtectedRoute';
import Login from './Auth/Login';
import Signup from './Auth/Signup';
import Payment from './Payment';
import FullPageLayout from './Layout/FullPageLayout';
import ProductList from './Products/ProductList';
import OrderHistory from './OrderHistory/OrderHistory';
import Profile from './Profile/Profile';
import ProductManagement from './ProductManagement/ProductManagement';
import CreateProduct from './ProductManagement/CreateProduct';
import EditProduct from './ProductManagement/EditProduct';
import ShopeeLayout from './Layout/ShopeeLayout';
import ProductDetail from './Products/ProductDetail';
import OrderManagement from './OrderManagement/index';
import BookShelf from './components/BookShelf';
import BookDetail from './Books/BookDetail';
import FileViewer from './components/FileViewer';

import { isAuthenticated } from './utils/auth';


function App() {

  return (
    <Router>
      <CartProvider>
      {/* <POS /> */}
        {/* <FullPageLayout> */}
        <ShopeeLayout>
          <Routes>
            <Route path="/" element={<ProductList />} />
            <Route path="/books" element={<BookShelf />} />
            <Route path="/books/:id" element={<BookDetail />} />
            <Route path="/viewer" element={<FileViewer />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/product/:productId" element={<ProductDetail />} />
            <Route path="/order-management" element={<OrderManagement />} />
            <Route path='/home' element={<ProductList />} />
            <Route path='/payment' element={<ProtectedRoute><Payment /></ProtectedRoute>} />
            <Route path='/order-history' element={<ProtectedRoute><OrderHistory /></ProtectedRoute>} />
            <Route path='/profile' element={<ProtectedRoute><Profile /></ProtectedRoute>} />
            <Route path="/products" element={<ProductManagement />} />
            <Route path="/products/create" element={<CreateProduct />} />
            <Route path="/products/edit/:id" element={<EditProduct />} />


          </Routes>
        </ShopeeLayout>
        {/* </FullPageLayout> */}
      </CartProvider>
    </Router>
  );
}

export default App;
