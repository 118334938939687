// src/components/BookDetail.js

import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom'; // Import Link for navigation
import { Card, Spin, Typography, Button } from 'antd';
import bookService from '../services/bookService';

const { Title, Paragraph } = Typography;

const BookDetail = () => {
  const { id } = useParams();
  const [book, setBook] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    bookService
      .getBookById(id)
      .then((data) => {
        setBook(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching book details:', error);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (!book) {
    return <div>Book not found.</div>;
  }

  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <Card
        cover={
          <img
            alt={book.title}
            src={book.cover_url || 'https://via.placeholder.com/300'}
          />
        }
      >
        <Title level={2}>{book.title}</Title>
        <Paragraph><strong>ผู้เขียน:</strong> {book.author}</Paragraph>
        <Paragraph><strong>รายละเอียด:</strong> {book.description || 'No description available.'}</Paragraph>
        <Button type="primary" href={book.pdf_url} target="_blank" style={{ marginTop: '20px' }}>
          เปิดไฟล์ PDF
        </Button>
        <Link to={`/books/${book.id}/pdf`}>
          <Button type="default" style={{ marginTop: '10px' }}>
            อ่าน
          </Button>
        </Link>
      </Card>
    </div>
  );
};

export default BookDetail;
