import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Layout, Menu, Dropdown, Avatar, Badge, Input, Button, Popover } from 'antd';
import { UserOutlined, ShoppingCartOutlined, GlobalOutlined, MenuOutlined, HomeOutlined, InfoCircleOutlined, AppstoreOutlined, MailOutlined, ProductOutlined, HistoryOutlined } from '@ant-design/icons';
import Logo from '../logo.png'; // Replace with Shopee-like logo
import { isAuthenticated } from '../utils/auth';
import CartFloatButton from '../components/CartFloatButton';

const { Header } = Layout;
const { Search } = Input;

const Navbar = () => {
    const [isLogedIn, setIslogedIn] = React.useState();
    const navigate = useNavigate();

    React.useEffect(() => {
        setIslogedIn(isAuthenticated());
    }, [isAuthenticated, navigate]);

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/');
    };

    const languageMenu = (
        <Menu>
            <Menu.Item key="1">ไทย</Menu.Item>
            <Menu.Item key="2">English</Menu.Item>
        </Menu>
    );

    const profileMenu = (
        <Menu>
            {!isLogedIn && <>
                <Menu.Item key="1" onClick={() => navigate('/home')}>
                    หน้าแรก
                </Menu.Item>
                <Menu.Item key="2" onClick={() => navigate('/signup')}>
                    ลงทะเบียน
                </Menu.Item>
                <Menu.Item key="3" onClick={() => navigate('/login')}>
                    เข้าสู่ระบบ
                </Menu.Item>
            </>}

            {isLogedIn && <>
                <Menu.Item key="1" onClick={() => navigate('/home')}>
                    รายการสินค้า
                </Menu.Item>
                <Menu.Item key="2">
                    <Link to="/profile">บัญชีของฉัน</Link>
                </Menu.Item>
                <Menu.Item key="3">
                    <Link to="/order-history">ประวัติการสั่งซื้อ</Link>
                </Menu.Item>
                <Menu.Item key="4" onClick={handleLogout}>
                    ออกจากระบบ
                </Menu.Item>
            </>}
        </Menu>
    );

    return (
        <Header className="header" style={{ background: 'linear-gradient(-180deg,#1677ff, #0958d9)', padding: '0 20px' }}>
            <div className="container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="logo">
                    <Link to="/home">
                        <img src={Logo} alt="atomsoft" style={{ height: 40 }} />
                    </Link>
                </div>
                <Menu mode="inline" className="transparent-menu" inlineCollapsed={true}>
                    <Menu.Item key="home" icon={<HomeOutlined />}>
                        <Link to="/home">
                            หน้าหลัก
                        </Link>
                    </Menu.Item>
                    {isAuthenticated && (
                        <Menu.Item key="about" icon={<HistoryOutlined />}>
                            <Link to="/order-history">
                                ประวัติการสั่งซื้อ
                            </Link>
                        </Menu.Item>
                    )}
                </Menu>

                {/* <Search
                    placeholder="พิมพ์คำค้นหาสินค้า"
                    enterButton
                    style={{ width: 400 }}
                    onSearch={(value) => console.log(value)}
                /> */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CartFloatButton />
                    {isAuthenticated ? (
                        <Dropdown overlay={profileMenu} placement="bottomRight">
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginLeft: 20 }}>
                                <Avatar icon={<UserOutlined />} />
                                <span style={{ color: '#fff', marginLeft: 10 }}>{'บัญชี'}</span>
                            </div>
                        </Dropdown>
                    ) : (
                        <div style={{ marginLeft: 20 }}>
                            <Link to="/signup" style={{ color: '#fff', marginRight: 15 }}>
                                สมัคร
                            </Link>
                            <Link to="/login" style={{ color: '#fff' }}>
                                เข้าสู่ระบบ
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </Header>
    );
};

export default Navbar;
