// src/components/BookShelf.js

import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Spin } from 'antd';
import { Link } from 'react-router-dom';
import bookService from '../services/bookService';


const { Meta } = Card;

const BookShelf = () => {
    const [books, setBooks] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        bookService
            .getAllBooks()
            .then((data) => {
                setBooks(data);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error('Error fetching books:', error);
            });
    }, []);

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Spin size="large" />
            </div>
        );
    }

    return (
        <div style={{ padding: '20px' }}>
            <Row gutter={[16, 16]}>
                {books.map((book) => (
                    <Col xs={24} sm={12} md={8} lg={6} key={book.id}>
                        <Link to={`/books/${book.id}`}>
                            <Card
                                hoverable
                                cover={
                                    <img
                                        alt={book.title}
                                        src={book.cover_url || 'https://via.placeholder.com/150'}
                                        style={{ width: '100%', height: '650px', objectFit: 'cover' }}
                                    />
                                }
                            >
                                <Meta title={book.title} description={book.author} />
                            </Card>
                        </Link>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default BookShelf;
