import React, { useEffect, useState } from 'react';
import TopCenteredLayout from './Layout/TopCenteredLayout';
import { Button, Result, Card, List, Divider, Typography, notification, message } from 'antd';
import { Modal } from 'antd';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useCart } from './context/CartContext'; // Assume you're using a CartContext for cart management
import { useNavigate } from 'react-router-dom';
import CountdownTimer from './components/CountdownTime';
import endpoints from './apis/apiConfig';



const { Title } = Typography;

const Payment = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { clearCart } = useCart(); // Function to clear the cart
    const { cartItems } = location.state || { cartItems: [] };
    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const [chargeId, setChargeId] = useState('');
    const [orderId, setOrderId] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState('Processing');
    const [loading, setLoading] = useState(false);


    // Calculate the total amount
    const totalAmount = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);


    const handleCheckout = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token'); // Assuming you're storing the token in localStorage

            const response = await axios.post(endpoints.createOrder, {
                cartItems,
                totalAmount
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            setOrderId(response.data.orderId);

            // Clear the cart
            clearCart();

            //alert('Order created successfully!');
            notification.success({
                message: 'สร้างคำสั่งซื้อสำเร็จ',
                description: 'Order created successfully!',
            });

            scrollToBottom();

        } catch (error) {
            console.error('Error creating order:', error);
            alert('Failed to create order');
        } finally {
            setLoading(false);
        }
    };


    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth' // Optional: Adds smooth scrolling
        });
    };

    useEffect(() => {

        console.log('cartItems', cartItems);

        // Calculate the total amount in Satang (1 Baht = 100 Satang)
        const totalAmountInBaht = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
        const totalAmountInSatang = totalAmountInBaht * 100; // Convert to Satang

        // Order details to be sent to the backend
        const orderDetails = {
            amount: totalAmountInSatang,
            currency: 'THB',
            description: 'ยืนยันคำสั่งซื้อ',
            items: cartItems.map(item => ({
                name: item.name,
                quantity: item.quantity,
                price: item.price,
            })),
        };

        // Create the charge when the component mounts
        fetch(endpoints.createCharge, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(orderDetails),
        })
            .then(response => response.json())
            .then(data => {
                setQrCodeUrl(data.qrCodeUrl);
                setChargeId(data.chargeId);
            })
            .catch(error => console.error('Error creating charge:', error));
    }, [cartItems]);

    useEffect(() => {
        if (chargeId) {
            // Poll for payment status every 3 seconds
            const interval = setInterval(() => {
                fetch(`${endpoints.checkPayment}/${chargeId}`)
                    .then(response => response.json())
                    .then(data => {
                        console.log('paymentData',data);
                        if (data.paid) {
                            setPaymentStatus('Successful');
                            clearInterval(interval); // Stop polling
                            updateOrderStatus();
                        }
                    })
                    .catch(error => console.error('Error checking payment status:', error));
            }, 3000);

            return () => clearInterval(interval); // Clean up on component unmount
        }
    }, [chargeId, orderId]);

    // Function to notify backend of payment success
    const updateOrderStatus = async () => {
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post(endpoints.paymentSuccess, {
                orderId: orderId,
                paymentStatus: 'success', // Assume payment status is provided
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            if (response.status === 200) {
                message.success('ชำระเงินสำเร็จ อัพเดทสถานะคำสั่งซื้อเรียบร้อย!');
            } else {
                message.error('Failed to update order status');
            }
        } catch (error) {
            message.error('Error communicating with server');
        } finally {
            setLoading(false);
        }
    };

    return (
        <TopCenteredLayout>
            <div style={{ padding: '24px', maxWidth: '600px', margin: '0 auto' }}>
                <Card title="สรุปการสั่งซื้อ" bordered={false}>
                    <List
                        itemLayout="horizontal"
                        dataSource={cartItems}
                        renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                    title={item.name}
                                    description={`Quantity: ${item.quantity} x ${item.price}`}
                                />
                                <div>{(item.price * item.quantity)}</div>
                            </List.Item>
                        )}
                    />
                    <Divider />
                    <Title level={4} style={{ textAlign: 'right' }}>
                        รวมเป็นเงิน: {totalAmount.toFixed(2)}
                    </Title>
                    <Button
                        type="primary"
                        block
                        onClick={handleCheckout}
                        disabled={orderId ? true : false}
                    >
                        ยืนยันคำสั่งซื้อ
                    </Button>
                </Card>
            </div>
            {paymentStatus === 'Successful' ? <p>
                <Result
                    status="success"
                    title="ชำระเงินสำเร็จ"
                    extra={[
                        <p>สถานะ: {paymentStatus}</p>,
                        <Button type="primary" key="console"
                        onClick={v=>{
                            navigate('/home');
                        }}
                        >
                            ไปที่หน้าแรก
                        </Button>,
                    ]}
                />
            </p>:orderId&&<Result
                // status="success"
                title={`คำสั่งซื้อที่  ${orderId}`}
                subTitle="การประมวลผลรายการอาจใช้เวลาสักครู่กรุณาอย่าปิดหน้านี้..."
                extra={[
                    qrCodeUrl && <img src={qrCodeUrl} alt="PromptPay QR Code" width={'100vh'} style={{ minWidth: '250px' }} />,
                    <CountdownTimer minutes={5} />,
                    <p>สถานะ: {paymentStatus}</p>
                ]}
            />
            }

            

        </TopCenteredLayout>
    );
};

export default Payment;
