import React, { useContext } from 'react';
import { List, Button } from 'antd';
import { CartContext } from '../context/CartContext';

const ShoppingCart = () => {
  const { cartItems, removeFromCart } = useContext(CartContext);

  return (
    <div style={{ padding: '20px' }}>
      <h2>ตะกร้าสินค้า</h2>
      <List
        itemLayout="horizontal"
        dataSource={cartItems}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button onClick={() => removeFromCart(item.id)}>ลบ</Button>,
            ]}
          >
            <List.Item.Meta
              avatar={<img alt={item.name} src={item.image_url} width={50} />}
              title={item.name}
              description={`${item.price} x ${item.quantity}`}
            />
            <div>รวม: {(item.price * item.quantity).toFixed(2)}</div>
          </List.Item>
        )}
      />
    </div>
  );
};

export default ShoppingCart;
