import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { List, Card, Typography, Tag, Descriptions, Button, Modal, Result, message } from 'antd';
import endpoints from '../apis/apiConfig';
import CountdownTimer from '../components/CountdownTime';

const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
dayjs.extend(timezone);

const { Title } = Typography;

const OrderHistory = () => {
  const [orders, setOrders] = useState([]);
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [chargeId, setChargeId] = useState('');
  const [orderId, setOrderId] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('Processing');

  const showModal = (data) => {
    //console.log(orderDetails);
    setOrderId(data.id);


    // Calculate the total amount in Satang (1 Baht = 100 Satang)
    const totalAmountInBaht = data.total_amount;
    const totalAmountInSatang = totalAmountInBaht * 100; // Convert to Satang


    // Order details to be sent to the backend
    const orderDetails = {
      amount: totalAmountInSatang,
      currency: 'THB',
      description: 'ชำระค่าสินค้า คำสั่งซื้อที่# ' + data.id,
      items: data.items.map(item => ({
        name: item.name,
        quantity: item.quantity,
        price: item.price,
      })),
    };


    // Create the charge when the component mounts
    fetch(endpoints.createCharge, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(orderDetails),
    })
      .then(response => response.json())
      .then(data => {
        setQrCodeUrl(data.qrCodeUrl);
        setChargeId(data.chargeId);
      })
      .catch(error => console.error('Error creating charge:', error));


    setOpen(true);
  };
  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };
  const handleCancel = () => {
    setOpen(false);
  };


  useEffect(() => {
    if (chargeId) {
      // Poll for payment status every 3 seconds
      const interval = setInterval(() => {
        fetch(`${endpoints.checkPayment}/${chargeId}`)
          .then(response => response.json())
          .then(data => {
            if (data.paid) {
              setPaymentStatus('Successful');
              clearInterval(interval); // Stop polling
              updateOrderStatus(); // Update order status on backend
            }
          })
          .catch(error => console.error('Error checking payment status:', error));
      }, 3000);

      return () => clearInterval(interval); // Clean up on component unmount
    }
  }, [chargeId]);

  // Function to notify backend of payment success
  const updateOrderStatus = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post(endpoints.paymentSuccess, {
        orderId: orderId,
        paymentStatus: 'success', // Assume payment status is provided
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });

      if (response.status === 200) {
        message.success('Order status updated successfully!');
        // Fetch new order data
        fetchOrders();
        // Optionally navigate to a success page or update UI
        handleCancel();
      } else {
        message.error('Failed to update order status');
      }
    } catch (error) {
      message.error('Error communicating with server');
    } finally {
      setLoading(false);
    }
  };


  const fetchOrders = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(endpoints.fetchOrders, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      //console.log('orders', response.data)
      setOrders(response.data);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);


  const toThaiDate = (value) => {
    // Convert datetime to Bangkok timezone
    const bangkokTime = dayjs(value).format('DD-MM-YYYY HH:mm:ss'); //.tz('Asia/Bangkok')
    //console.log('Bangkok Time:', bangkokTime);
    return bangkokTime
  }


  const getStatusTag = (status) => {
    switch (status) {
      case 'Unpaid':
        return <Tag color="orange">รอชำระเงิน</Tag>;
      case 'Paid':
        return <Tag color="green">ชำระเงินแล้ว</Tag>;
      case 'Pending':
        return <Tag color="orange">รอดำเนินการ</Tag>;
      case 'Shipped':
        return <Tag color="blue">ระหว่างจัดส่ง</Tag>;
      case 'Delivered':
        return <Tag color="green">จัดส่งเรียบร้อย</Tag>;
      case 'Processing':
        return <Tag color="orange">รอชำระเงิน</Tag>;
      default:
        return <Tag color="red">ยกเลิก</Tag>;
    }
  };

  return (
    <div style={{ padding: '10px', marginTop: '10px' }}>
      <Modal
        open={open}
        title="ชำระเงิน"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            ปิด
          </Button>,
        ]}
      >

        {paymentStatus === 'Successful' ? <p>
          <Result
            status="success"
            title="ชำระเงินสำเร็จ"
            extra={[
              <p>สถานะการชำระเงิน: {paymentStatus}</p>,
            ]}
          />
        </p> : <Result
          // status="success"
          title={`คำสั่งซื้อที่  ${orderId}`}
          subTitle="การประมวลผลรายการอาจใช้เวลาสักครู่กรุณาอย่าปิดหน้านี้..."
          extra={[
            qrCodeUrl && <img src={qrCodeUrl} alt="PromptPay QR Code" width={'100vh'} style={{ minWidth: '250px' }} />,
            <CountdownTimer minutes={5} />,
            <p>สถานะ: {paymentStatus}</p>
          ]}
        />}
      </Modal>
      <Title level={3}>ประวัติการสั่งซื้อ</Title>
      <List
        grid={{ gutter: 16, column: 1 }}
        dataSource={orders}
        renderItem={order => (
          <List.Item>
            <Card title={`คำสั่งที่ #${order.id}`} extra={<>
              <Button type="primary"
                disabled={order.status === 'Unpaid' || order.status === 'Processing'  ? false : true}
                onClick={() => { setOrderId(order.order_id); showModal(order) }} style={{ marginRight: 10 }}>
                ชำระเงิน
              </Button>
              {getStatusTag(order.status)}
            </>}>
              <Descriptions column={1}>
                <Descriptions.Item label="เลขที่คำสั่งซื้อ">{order.id}</Descriptions.Item>
                <Descriptions.Item label="วันที่สั่งซื้อ">{toThaiDate(order.created_at)}</Descriptions.Item>
                <Descriptions.Item label="รวมเป็นเงิน">
                  {order.total_amount}
                </Descriptions.Item>
              </Descriptions>
              <List
                itemLayout="horizontal"
                dataSource={order.items}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.name}
                      description={`จำนวน: ${item.quantity} x ฿${item.price}`}
                    />
                    <div>{(item.price * item.quantity).toFixed(2)}</div>
                  </List.Item>
                )}
              />
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
};

export default OrderHistory;
