// src/services/bookService.js
import endpoints from '../apis/apiConfig';

import axios from 'axios';

// Create an Axios instance with a base URL
const apiClient = axios.create({
  baseURL: 'http://localhost:3001', // Replace with your backend API base URL
  headers: {
    'Content-Type': 'application/json',
  },
});

// Service to fetch all books
const getAllBooks = async () => {
  try {
    const response = await apiClient.get('/api/books');
    return response.data;
  } catch (error) {
    console.error('Error fetching books:', error);
    throw error;
  }
};

// Service to fetch a single book by ID
const getBookById = async (id) => {
  try {
    const response = await apiClient.get(`/api/books/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching book with ID ${id}:`, error);
    throw error;
  }
};

// Service to add a new book
const addBook = async (bookData) => {
  try {
    const response = await apiClient.post('/api/books', bookData);
    return response.data;
  } catch (error) {
    console.error('Error adding book:', error);
    throw error;
  }
};

// Service to update a book by ID
const updateBookById = async (id, bookData) => {
  try {
    const response = await apiClient.put(`/api/books/${id}`, bookData);
    return response.data;
  } catch (error) {
    console.error(`Error updating book with ID ${id}:`, error);
    throw error;
  }
};

// Service to delete a book by ID
const deleteBookById = async (id) => {
  try {
    const response = await apiClient.delete(`/api/books/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting book with ID ${id}:`, error);
    throw error;
  }
};

// Export the services
export default {
  getAllBooks,
  getBookById,
  addBook,
  updateBookById,
  deleteBookById,
};
