import React from 'react';
import { Layout } from 'antd';
import './TopCenteredLayout.css';

const { Content } = Layout;

const TopCenteredLayout = ({ children }) => {
  return (
    <Layout className="layout">
      <Content className="top-centered-content">
        {children}
      </Content>
    </Layout>
  );
};

export default TopCenteredLayout;
