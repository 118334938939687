import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FloatButton, Badge, Drawer, Avatar, Space, Button } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import ShoppingCart from './ShoppingCart';
import { CartContext } from '../context/CartContext';

const CartFloatButton = () => {
    const { cartItems } = useContext(CartContext);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const navigate = useNavigate();

    const toggleDrawer = () => {
        setDrawerVisible(!drawerVisible);
    };

  // Handle the checkout button click
  const handleCheckout = () => {
    // Navigate to the payment screen with cartItems data
    navigate('/payment', { state: { cartItems } });
    setDrawerVisible(!drawerVisible);
  };


    // Calculate the total number of items in the cart
    const totalItems = cartItems.reduce((total, item) => total + item.quantity, 0);

    return (
        <>
            <FloatButton
                icon={<ShoppingCartOutlined size={100}/>}
                onClick={toggleDrawer}
                style={{ right: 24, top: 100 }}
                badge={{
                    count: totalItems,
                    color: 'red',
                }}
            />
            <Drawer
                title="ตะกร้าสินค้า"
                placement="right"
                onClose={toggleDrawer}
                visible={drawerVisible}
                width={450}
                footer={
                    <Button
                      type="primary"
                      block
                      onClick={handleCheckout}
                      disabled={totalItems === 0} // Disable checkout if cart is empty
                    >
                      สั่งซื้อ และชำระเงิน
                    </Button>
                  }
            >
                <ShoppingCart />
            </Drawer>
        </>
    );
};

export default CartFloatButton;
