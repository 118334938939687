import React, { useEffect, useState } from 'react';

const CountdownTimer = ({ minutes }) => {
  const [timeRemaining, setTimeRemaining] = useState(minutes * 60);

  useEffect(() => {
    const targetTime = new Date().getTime() + minutes * 60 * 1000;

    const updateCountdown = () => {
      const currentTime = new Date().getTime();
      const timeLeft = Math.floor((targetTime - currentTime) / 1000);

      if (timeLeft >= 0) {
        setTimeRemaining(timeLeft);
      } else {
        clearInterval(intervalId);
      }
    };

    const intervalId = setInterval(updateCountdown, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [minutes]);

  const formatTime = (time) => {
    const minutesLeft = Math.floor(time / 60);
    const secondsLeft = time % 60;
    return `${minutesLeft}m ${secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft}s`;
  };

  return (
    <div>
      <h1>{formatTime(timeRemaining)}</h1>
    </div>
  );
};

export default CountdownTimer;
