import React, { useState, useEffect } from 'react';
import { Card, Form, Input, Button, Typography, notification } from 'antd';
import axios from 'axios';
import endpoints from '../apis/apiConfig';

const { Title } = Typography;

const Profile = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    // Fetch profile data from API when component mounts
    const fetchProfileData = async () => {
      try {
        const token = localStorage.getItem(`token`);
        //console.log(`Fetching profile data`,token);
        const response = await axios.get(endpoints.profile,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })

        const profileData = response.data;
        setUserId(profileData.id);
        // Set the form values with the fetched data
        form.setFieldsValue({
          name: profileData.name,
          email: profileData.email,
          phone: profileData.phone,
          address: profileData.address,
        });
      } catch (error) {
        console.error('Error fetching profile data:', error);
        notification.error({
          message: 'Error',
          description: 'Failed to load profile data.',
        });
      }
    };

    fetchProfileData();
  }, [form]);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      // Simulate API call to update profile data
      await axios.put(endpoints.profile, {...values, id: userId},  {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      notification.success({
        message: 'อัพเดทโปรไฟล์เรียบร้อย',
        description: 'Your profile information has been successfully updated.',
      });
    } catch (error) {
      console.error('Error updating profile:', error);
      notification.error({
        message: 'Error',
        description: 'Failed to update profile data.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: '10px', maxWidth: '600px', margin: '0 auto' }}>
      <Card>
        <Title level={2} style={{ fontFamily: 'Prompt' }}>โปรไฟล์</Title>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFormSubmit}
        >
          <Form.Item
            name="name"
            label="ชื่อ-นามสกุล"
            rules={[{ required: true, message: 'ป้อนชื่อ และนามสกุล' }]}
          >
            <Input placeholder="Enter your name" />
          </Form.Item>

          <Form.Item
            name="email"
            label="อีเมล์ (ไม่สามารถแก้ไขได้)"
            rules={[{ required: true, message: 'ป้อนอีเมล์' }, { type: 'email', message: 'กรุณาป้อนอีเมล์ให้ถูกต้อง' }]}
          >
            <Input placeholder="Enter your email" readOnly/>
          </Form.Item>

          <Form.Item
            name="phone"
            label="เบอร์โทร"
            rules={[{ required: true, message: 'เบอร์ที่สามารถติดต่อได้' }]}
          >
            <Input placeholder="กรุณาป้อนเบอร์ที่สามารถติดต่อได้" />
          </Form.Item>

          <Form.Item
            name="address"
            label="ที่อยู่ร้าน"
            rules={[{ required: true, message: 'ป้อนที่อยู่ร้านค้า' }]}
          >
            <Input.TextArea placeholder="ป้อนที่อยู่ร้านค้าของคุณ" rows={4} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} block>
              บันทึก
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Profile;
