import React, { useState } from 'react';
import { Form, Input, InputNumber, Button, message } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import endpoints from '../apis/apiConfig';

const CreateProduct = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      await axios.post(endpoints.products, values);
      message.success('Product created successfully!');
      navigate('/products');
    } catch (error) {
      message.error('Failed to create product');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form layout="vertical" onFinish={handleFormSubmit}>
      <Form.Item
        name="name"
        label="Product Name"
        rules={[{ required: true, message: 'Please enter product name' }]}
      >
        <Input placeholder="Enter product name" />
      </Form.Item>

      <Form.Item
        name="description"
        label="Description"
      >
        <Input.TextArea placeholder="Enter product description" />
      </Form.Item>

      <Form.Item
        name="price"
        label="Price"
        rules={[{ required: true, message: 'Please enter product price' }]}
      >
        <InputNumber
          min={0}
          placeholder="Enter product price"
          style={{ width: '100%' }}
        />
      </Form.Item>

      <Form.Item
        name="stock"
        label="Stock"
      >
        <InputNumber
          min={0}
          placeholder="Enter stock quantity"
          style={{ width: '100%' }}
        />
      </Form.Item>

      <Form.Item
        name="image_url"
        label="Image URL"
      >
        <Input placeholder="Enter image URL" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Create Product
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateProduct;
