import React, { useState } from 'react';
import { Form, Input, Button, notification } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import endpoints from '../apis/apiConfig';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(endpoints.login, values);
      localStorage.setItem('token', response.data.token);
      navigate('/profile');
      notification.success({
        message: 'เข้าสู่ระบบสำเร็จ',
        description: 'loged in successfully',
      });
    } catch (error) {
      notification.error({
        message: 'ชื่อผู้ใช้ หรือรหัสผ่านอาจไม่ถูกต้อง',
        description: 'Invalid credentials. Please try again.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <h2>เข้าสู่ระบบ</h2>
      <Form
        name="login"
        layout="vertical"
        onFinish={handleLogin}
        style={{ maxWidth: 400, margin: 'auto' }}
      >
        <Form.Item
          name="email"
          label="อีเมล์"
          rules={[{ required: true, type: 'email', message: 'รูปแบบอีเมล์ไม่ถูกต้อง!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="password"
          label="รหัสผ่าน"
          rules={[{ required: true, message: 'ป้อนรหัสผ่าน!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            เข้าสู่ระบบ
          </Button>
        </Form.Item>
      </Form>
      <Button type="link" onClick={()=>navigate('/signup')}>ลงทะเบียน</Button>
    </div>
  );
};

export default Login;
