import React, { useState } from 'react';
import { Layout, Menu, Breadcrumb, Row, Col, Card, Avatar } from 'antd';
import {
  UserOutlined,
  ShoppingCartOutlined,
  ShopOutlined,
  HomeOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';
import Navbar from './Navbar';
import { useNavigate } from 'react-router-dom';


const { Header, Content, Footer, Sider } = Layout;

const ShopeeLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {/* Header */}
      <Navbar /> {/* Include Navbar at the top */}

      {/* Layout Content */}
      <Layout style={{ marginTop: 10}}>
        {/* Sidebar */}
        {/* <Sider
          width={200}
          className="site-layout-background"
          collapsible
          collapsed={collapsed}
          onCollapse={toggleCollapsed}
          breakpoint="lg"
          collapsedWidth="0"
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={['1']}
            style={{ height: '100%', borderRight: 0 }}
          >
            <Menu.Item key="1" icon={<ShopOutlined />} onClick={()=>navigate('/products')}>All Products</Menu.Item>
            <Menu.Item key="2" icon={<ShopOutlined />}>Electronics</Menu.Item>
            <Menu.Item key="3" icon={<ShopOutlined />}>Fashion</Menu.Item>
            <Menu.Item key="4" icon={<ShopOutlined />}>Home & Living</Menu.Item>
          </Menu>
        </Sider> */}

        {/* Main Content */}
        <Layout style={{ padding: '0 10px 5px' }}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>All Products</Breadcrumb.Item>
          </Breadcrumb>
          <Content
            className="site-layout-background"
            style={{
              padding: 5,
              margin: 0,
              minHeight: 280,
              background: '#fff',
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>

      {/* Footer */}
      <Footer style={{ textAlign: 'center' }}>Client Portal ©2024 Created by Atomsoft</Footer>
    </Layout>
  );
};

export default ShopeeLayout;
