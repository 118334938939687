import React, { useState, useEffect } from 'react';
import { Table, Tag, Space, Button, Modal, Select, Input, Row, Col, Descriptions } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import endpoints from '../apis/apiConfig';
import { formatCurrency } from '../utils/formatNumber';

const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
dayjs.extend(timezone);

const { Option } = Select;

const OrderManagement = () => {
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [statusFilter, setStatusFilter] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        // Fetch orders from the backend
        const token = localStorage.getItem(`token`);
   
        axios.get(endpoints.adminOrders,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(response => {
                //console.log(`Fetching order data`,response);
                setOrders(response.data);
            });
    }, []);

    const showModal = (order) => {
        setSelectedOrder(order);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleStatusChange = (orderId, status) => {
        const token = localStorage.getItem(`token`);
        axios.put(`${endpoints.orders}/${orderId}/status`, { status }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(() => {
                setOrders(prevOrders => prevOrders.map(order =>
                    order.id === orderId ? { ...order, status } : order
                ));
            });
    };

    const filteredOrders = orders.filter(order =>
        order.status.includes(statusFilter) &&
        order.id.toString().includes(searchTerm)
    );

    
  const getStatusTag = (status) => {
    switch (status) {
      case 'Unpaid':
        return <Tag color="orange">รอชำระเงิน</Tag>;
      case 'Paid':
        return <Tag color="green">ชำระเงินแล้ว</Tag>;
      case 'Pending':
        return <Tag color="orange">รอดำเนินการ</Tag>;
      case 'Shipped':
        return <Tag color="blue">ระหว่างจัดส่ง</Tag>;
      case 'Delivered':
        return <Tag color="green">จัดส่งเรียบร้อย</Tag>;
      case 'Processing':
        return <Tag color="orange">รอชำระเงิน</Tag>;
      default:
        return <Tag color="red">ยกเลิก</Tag>;
    }
  };

  const toThaiDate = (value) => {
    // Convert datetime to Bangkok timezone
    const bangkokTime = dayjs(value).format('DD-MM-YYYY HH:mm:ss'); //.tz('Asia/Bangkok')
    //console.log('Bangkok Time:', bangkokTime);
    return bangkokTime
  }

    const columns = [
        {
            title: 'เลขที่คำสั่งซื้อ',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => <a onClick={() => {
                showModal(record);
            }}>{text}</a>,
        },
        {
            title: 'สั่งซื้อเมื่อ',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text, record) =><Tag>{toThaiDate(record.created_at)}</Tag>,
        },
        {
            title: 'ชื่อลูกค้า',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'รวมเป็นเงิน',
            dataIndex: 'total_amount',
            key: 'total_amount',
            render: amount => <span>{amount.toLocaleString()}</span>,
        },
        {
            title: 'สถานะ',
            key: 'status',
            dataIndex: 'status',
            render: status => {
                let color = 'geekblue';
                if (status === 'Shipped') color = 'green';
                if (status === 'Cancelled') color = 'volcano';
                return <Tag color={color}>{status.toUpperCase()}</Tag>;
            },
        },
        {
            title: 'ดำเนินการ',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Select
                        value={record.status}
                        onChange={(value) => handleStatusChange(record.id, value)}
                    >
                        <Option value="Unpaid">ยังไม่ชำระ</Option>
                        <Option value="Paid">ชำระแล้ว</Option>
                        <Option value="Pending">รอดำเนินการ</Option>
                        <Option value="Processing">รอชำระเงิน</Option>
                        <Option value="Shipped">จัดส่งแล้ว</Option>
                        <Option value="Delivered">ได้รับแล้ว</Option>
                        <Option value="Cancelled">ยกเลิก</Option>
                    </Select>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <Row gutter={16} style={{ marginBottom: '16px' }}>
                <Col span={12}>
                    <Input
                        placeholder="ค้นหาด้วยหมายเลขคำสั่งซื้อ"
                        prefix={<SearchOutlined />}
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />
                </Col>
                <Col span={12}>
                    <Select
                        placeholder="กรองตามสถานะ"
                        style={{ width: '100%' }}
                        onChange={value => setStatusFilter(value)}
                    >
                        <Option value="">ทั้งหมด</Option>
                        <Option value="Unpaid">ยังไม่ชำระ</Option>
                        <Option value="Paid">ชำระแล้ว</Option>
                        <Option value="Pending">รอดำเนินการ</Option>
                        <Option value="Processing">รอชำระเงิน</Option>
                        <Option value="Shipped">จังส่งแล้ว</Option>
                        <Option value="Delivered">ได้รับแล้ว</Option>
                        <Option value="Cancelled">ยกเลิก</Option>
                    </Select>
                </Col>
            </Row>
            <Table columns={columns} dataSource={filteredOrders} rowKey="id" />

            <Modal
                title={`รายละเอียดคำสั่งซื้อ: ${selectedOrder?.id}`}
                visible={isModalVisible}
                // onOk={handleOk}
                onCancel={handleCancel}
                width={800}
                // don't show ok button
                footer={<Button type="primary" onClick={handleOk} block>ปิดหน้าต่าง</Button>}
                okText="ตกลง"
                cancelText="ปิด">
                {selectedOrder && (
                    <Descriptions bordered>
                        <Descriptions.Item label="เลขที่คำสั่งซื้อ">{selectedOrder.id}</Descriptions.Item>
                        <Descriptions.Item label="ชื่อลูกค้า">{selectedOrder.name}</Descriptions.Item>
                        <Descriptions.Item label="ยอดรวม">{formatCurrency(selectedOrder.total_amount)}</Descriptions.Item>
                        <Descriptions.Item label="สถานะ">{getStatusTag(selectedOrder.status)}</Descriptions.Item>
                        <Descriptions.Item label="สั่งซื้อเมื่อ">{toThaiDate(selectedOrder.created_at)}</Descriptions.Item>
                        <Descriptions.Item label="แก้ไขล่าสุด">{toThaiDate(selectedOrder.updated_at)}</Descriptions.Item>
                        {/* <Descriptions.Item label="Shipping Address" span={3}>
              {selectedOrder.shipping_address}
            </Descriptions.Item> */}
                        <Descriptions.Item label="สินค้าที่สั่งซื้อ" span={3}>
                            {selectedOrder.items.map(item => (
                                <div key={item.id}>
                                    <b>{item.name}</b> - จำนวน: {item.quantity} - ราคา/หน่วย: {formatCurrency(item.price)}
                                </div>
                            ))}
                        </Descriptions.Item>
                    </Descriptions>
                )}
            </Modal>
        </div>
    );
};

export default OrderManagement;
