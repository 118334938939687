import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, message } from 'antd';
import ProductCard from './ProductCard';
import axios from 'axios';
import endpoints from '../apis/apiConfig';

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const token = localStorage.getItem(`token`);
      const response = await axios.get(endpoints.products,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      //console.log(response);
      setProducts(response.data);
    } catch (error) {
      message.error('Failed to load products');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: '5px' }}>
      <Row gutter={[16, 16]}>
        {products.map((product) => (
          <Col key={product.id} xs={24} sm={12} md={8} lg={6} xl={4}>
              <ProductCard product={product} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ProductList;
