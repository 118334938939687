// src/apis/apiConfig.js
// https://pos-membership-backend.vdqx78.easypanel.host
const API_BASE_URL = 'https://pos-membership-backend.vdqx78.easypanel.host';

const endpoints = {
  // Auth Endpoints
  login: `${API_BASE_URL}/api/login`,
  register: `${API_BASE_URL}/api/signup`,
  profile: `${API_BASE_URL}/api/profile`,

  // Product Endpoints
  products: `${API_BASE_URL}/api/products`,
  productSearch: `${API_BASE_URL}/api/products/search`,
  productDetails: (id) => `${API_BASE_URL}/api/products/${id}`,

  // Order Endpoints
  orders: `${API_BASE_URL}/api/orders`,
  adminOrders: `${API_BASE_URL}/api/orders/admin/all`,
  createOrder: `${API_BASE_URL}/api/orders/create`,
  userOrders: `${API_BASE_URL}/api/orders/user`,
  fetchOrders: `${API_BASE_URL}/api/orders`,
  updateOrderStatus: (id) => `${API_BASE_URL}/api/orders/${id}/status`,

  // Cart Endpoints
  cart: `${API_BASE_URL}/api/cart`,
  addToCart: `${API_BASE_URL}/api/cart/add`,
  removeFromCart: `${API_BASE_URL}/api/cart/remove`,

  // Payment Endpoints
  createCharge: `${API_BASE_URL}/api/create-charge`,
  checkPayment: `${API_BASE_URL}/api/check-payment`,
  paymentSuccess: `${API_BASE_URL}/api/payment/success`,


  // etc...
};

export default endpoints;
