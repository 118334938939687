import React, { useState } from 'react';
import { Layout, Menu, Breadcrumb } from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    HomeOutlined,
    HistoryOutlined,
    LogoutOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import CartFloatButton from '../components/CartFloatButton';
import { isAuthenticated } from '../utils/auth';
import './FullPageLayout.css';

const { Header, Sider, Content } = Layout;

const FullPageLayout = ({ children }) => {
    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate();


    const toggle = () => {
        setCollapsed(!collapsed);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/');
    };

    const handleMenuClick = (e) => {
        if (e.key === '1') {
            navigate('/home');
        } else if (e.key === '2') {
            navigate('/order-history');
        } else if (e.key === '3') {
            navigate('/profile');
        } else if (e.key === '4') {
            handleLogout();
        }
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
                onBreakpoint={(broken) => {
                    console.log(broken);
                }}
                onCollapse={(collapsed, type) => {
                    console.log(collapsed, type);
                }}>
                <div className="logo" />
                <Menu theme="dark" mode="inline"
                    // defaultSelectedKeys={['1']}
                    onClick={handleMenuClick}
                >
                    <Menu.Item key="1" icon={<HomeOutlined />}>
                        รายการสินค้า
                    </Menu.Item>
                    <Menu.Item key="2" icon={<HistoryOutlined />}>
                        ประวัติการสั่งซื้อ
                    </Menu.Item>
                    <Menu.Item key="3" icon={<UserOutlined />}>
                        โปรไฟล์
                    </Menu.Item>
                    {isAuthenticated() && (
                        <Menu.Item key="4" icon={<LogoutOutlined />}>
                            ออกจากระบบ
                        </Menu.Item>
                    )}
                </Menu>
            </Sider>
            <Layout className="site-layout">
                <Header className="site-layout-background" style={{ padding: 0 }}>
                    {collapsed ? (
                        <MenuUnfoldOutlined className="trigger" onClick={toggle} />
                    ) : (
                        <MenuFoldOutlined className="trigger" onClick={toggle} />
                    )}
                </Header>
                <Content
                    style={{
                        margin: '0px 5px',
                        padding: 0,
                        // minHeight: '100%',
                        backgroundColor: '#fff',
                        width: '100%', // Ensure full width
                        boxSizing: 'border-box', // Ensure padding doesn't affect width
                    }}
                >
                    <Breadcrumb style={{ margin: '16px 24px 24px 24px' }}>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>List</Breadcrumb.Item>
                        <Breadcrumb.Item>App</Breadcrumb.Item>
                    </Breadcrumb>
                    <div
                        style={{
                            padding: 5,
                            backgroundColor: '#fff',
                        }}
                    >
                        {children}
                    </div>
                    <CartFloatButton />
                </Content>
            </Layout>
        </Layout>
    );
};

export default FullPageLayout;
